import { IonImg } from "@ionic/react";
import React, { useContext } from "react";
import { AppContext } from "../../../context/app.context";
import {
  getActivityMetaData,
  stringInject,
} from "../../../utils/common.helper";
import BackButton from "../button-component/back";
import LogoOvante from "../../../assets/images/logo-ovante-small.svg";
import "./style.scss";
import ButtonComponent from "../button-component";

interface Props {
  pageName: any;
  backBtn?: boolean;
  customClass?: string;
  onBack?: () => void;
  textKey?: any;
  metaData?: any;
}

const RegisterHeader: React.FC<Props> = ({
  pageName,
  backBtn,
  customClass,
  onBack,
  textKey,
  metaData,
}) => {
  const { language, descEnFile, descFile }: any = useContext(AppContext);
  let title: any = pageName;
  if (textKey) {
    title =
      descFile[textKey.toLowerCase()] ||
      descEnFile[textKey.toLowerCase()] ||
      pageName;
    if (metaData) {
      const formattedData = getActivityMetaData(metaData, title, language);
      title = stringInject(title, formattedData);
    }
  }
  return (
    <div className={` register-header ${customClass || ""}`}>
      {/* <div className="register-header-desktop">
        <div className="register-header-desktop-logo">
          <IonImg src={LogoOvante} className="desktop-logo" />
        </div>
        <ButtonComponent 
          name="Download the App" 
          textKey={"download"}
          customClass="register-header-desktop-download"
        />
      </div> */}
      <div
        className={`${
          backBtn ? "register-header__withbutton" : "register-header__content"
        }`}
      >
        {backBtn && <BackButton onBack={onBack}></BackButton>}
        <div className="register-header__text">{title}</div>
      </div>
    </div>
  );
};
export default RegisterHeader;
