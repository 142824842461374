import React from "react";
import observerService from "../../../services/observer.service";
import "./style.scss";

interface Props {
  backdropVisible?: boolean;
  isOutsideClick?: boolean;
  onOutClick?: () => void;
}

const BackDrop: React.FC<Props> = ({
  backdropVisible,
  isOutsideClick,
  onOutClick,
}) => {
  const handleClose = () => {
    if (isOutsideClick) {
      observerService.handleEvents("backdrop", { state: "close" });
    }
    if (onOutClick) {
      onOutClick();
    }
  };

  return (
    <div
      className={`backdrop ${backdropVisible ? "fade-out" : "fade-in"}`}
      onClick={handleClose}
    ></div>
  );
};

export default BackDrop;
