import { IonImg, IonButton, IonContent } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import "./style.scss";
import BottomCurve from "../../assets/images/bottom-curve.svg";
import Rocket from "../../assets/images/rocket.svg"
import LanguageAppText from "../../components/languageapptext";

const StartQuiz: React.FC = () => {
  const history: any = useHistory();

  const handleStartQuiz = () => {
    history.replace({
      pathname: "/onboarding/diagnostic",
    });
  };

  return (
    <IonContent fullscreen>
      <div className="page-start-quiz">
        <RegisterHeader
          pageName="Business diagnostic"
          textKey={"business_diagnostic"}
          backBtn={true}
        ></RegisterHeader>
        <div className="page-content">
          <IonImg
            src={Rocket}
            className="page-content__rocket-icon"
          />
          <LanguageAppText
            tag={"p"}
            className="page-content__quiz_instruction"
            value={"Let’s get started"}
            textKey={"lets_get_started"}
          />
          <LanguageAppText
            tag={"p"}
            className="page-content__quiz_info"
            value={"The first thing we are going to do is go through a quick diagnostic quiz. This will help us determine what kind of expert and program to match you up with! It’s quick, and it’s easy."}
            textKey={"diagnostic_quiz_expert"}
          />
        </div>
        <div className="page-content__buttonquiz">
          <IonImg src={BottomCurve} className="bottom-curve-mobile"/>
          <ButtonComponent
            name="Start Quiz"
            textKey={"start_quiz"}
            customClass="button-quiz"
            onClick={handleStartQuiz}
          ></ButtonComponent>
        </div>
      </div>
    </IonContent>
  );
};

export default StartQuiz;
