import httpService, { HttpMethod } from "./http.service";

export const sendDeviceData = async (req: any) => {
  const resp = httpService.execute({
    url: "/userDevice",
    method: HttpMethod.POST,
    data: req,
  });
  return resp;
};
