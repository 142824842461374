import { IonItemDivider } from "@ionic/react";
import React from "react";
import "./style.scss";

const Divider = () => {
  return (
    <IonItemDivider className="item-divider"></IonItemDivider>
  )
}
export default Divider
