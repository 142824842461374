import { SQLite, SQLiteObject } from "@ionic-native/sqlite";
let _singleton = true;
let _instance: SQLiteService;
let sqliteDb: SQLiteObject;
class SQLiteService {
  constructor() {
    this.init();
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use SQLiteService.instance instead!"
      );
    }
  }

  public init = async () => {
    if (!sqliteDb) {
      sqliteDb = await SQLite.create({
        name: "ovante.db",
        location: "default",
      });
    }
  };

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new SQLiteService();
      // _instance.init();
      _singleton = true;
    }
    return _instance;
  }

  public async createTable(sqlStirng: string) {
    if (!sqliteDb) {
      await this.init();
    }
    sqliteDb.transaction((tx) => {
      tx.executeSql(
        sqlStirng,
        [],
        () => console.log("created"),
        () => console.log("Error in Create")
      );
    });
  }

  public insert(tablename: string, values?: any) {
    let query = "insert into " + tablename + " values (" + values + ")";
    return new Promise((resolve, reject) => {
      return sqliteDb.transaction((tx) =>
        tx.executeSql(
          query,
          undefined,
          (x: any, { insertId }: any) => resolve(insertId),
          (a: any, b: any) => resolve({ error: true, message: b.message })
        )
      );
    });
  }

  public async update(updateQuery: string) {
    return new Promise((resolve, reject) => {
      return sqliteDb.transaction((tx) =>
        tx.executeSql(
          updateQuery,
          undefined,
          (x: any, { rowsAffected }: any) => resolve(rowsAffected),
          (a: any, b: any) => resolve({ error: true, message: b.message })
        )
      );
    });
  }

  public async delete(query: string) {
    return new Promise((resolve, reject) => {
      return sqliteDb.transaction((tx) =>
        tx.executeSql(
          query,
          undefined,
          (x: any, { rowsAffected }: any) => resolve(rowsAffected),
          (a: any, b: any) => resolve({ error: true, message: b.message })
        )
      );
    });
  }

  public async getData(query: any) {
    return new Promise((resolve, reject) => {
      return sqliteDb.transaction((tx) =>
        tx.executeSql(
          query,
          undefined,
          (x: any, rs: any) => {
            console.log("res===", rs);
            return resolve(rs.rows);
          },
          (a: any, b: any) => resolve({ error: true, message: b.message })
        )
      );
    });
  }

  public async insertWithCustom(tablename: string, coloumn: any, values: any) {
    let query =
      "insert into " + tablename + "(" + coloumn + ") values (" + values + ")";

    return new Promise((resolve, reject) => {
      return sqliteDb.transaction((tx) =>
        tx.executeSql(
          query,
          undefined,
          (x: any, { insertId }: any) => resolve(insertId),
          (a: any, b: any) => resolve({ error: true, message: b.message })
        )
      );
    });
  }
}
export default SQLiteService;
