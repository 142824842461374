import "./style.scss";

import { Capacitor } from "@capacitor/core";
import { IonAvatar, IonButton, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { imageCapture } from "../../../utils/media.helper";
import { convertFilepath } from "../../../utils/common.helper";
import { uploadFile } from "../../../services/filetransfer.service";
import ProfilePlaceholder from "../../../assets/images/icons/profile-placeholder.svg";

interface Props {
  imgPath?: string;
  isEditable?: boolean;
  onEditPick?: any;
}

const ProfileImage: React.FC<Props> = ({ imgPath, isEditable, onEditPick }) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  const handleEditPic = async () => {
    onEditPick && onEditPick();
  };

  useEffect(() => {
    setMediaPath(imgPath);
  }, [imgPath]);

  return (
    <div className="profile_image" onClick={handleEditPic}>
      <IonAvatar className="ion-avatar">
        <IonImg
          src={mediaPath || ProfilePlaceholder}
          className="ion-avatar__image"
        />
      </IonAvatar>
      {isEditable && (
        <IonButton className="ion-button__edit-profile icon__edit-profile"></IonButton>
      )}
    </div>
  );
};

export default ProfileImage;
