import { IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import ExpertDefault from "../../assets/images/expertdefault.svg";
import PolygonIconWhite from "../../assets/images/icons/icon-polygon-white.svg";
import CheckedCircle from "../../assets/images/icons/checked-circle-white.svg";
import StepBar from "../common/stepbar";
import ButtonComponent from "../common/button-component";
import ExpertNew from "../../assets/images/expert-new.svg";
import { convertUTCToLocal, getFormattedUrl } from "../../utils/common.helper";
import { AppContext } from "../../context/app.context";
import LanguageText from "../languagetext";
import LanguageAppText from "../languageapptext";
interface Props {
  customClass?: string;
  imgPath?: string;
  data: any;
  isCurrent?: boolean;
  isCompleted?: boolean;
  isRecommended?: boolean;
  onButtonClick?: (data: any, isOtherActive?: boolean) => void;
}

const ExpertCard: React.FC<Props> = ({
  customClass,
  imgPath,
  data,
  isCompleted,
  isCurrent,
  isRecommended,
  onButtonClick,
}) => {
  const { language }: any = useContext(AppContext);
  const [mediaPath, setMediaPath] = useState(imgPath);
  let metaData = null;
  let stepBar = [];

  try {
    let parsedData = JSON.parse(data.metaData);
    if (parsedData) {
      metaData = parsedData[language] || parsedData["en"];
    }
  } catch (error) {}
  const isNewProgram =
    metaData &&
    metaData.isNew &&
    !isCurrent &&
    !isRecommended &&
    !isCompleted &&
    data.isComplete === null;

  const isOtherActive =
    data.activitiesCompleted != null &&
    data.activitiesCompleted != "" &&
    data.isActive != 1 &&
    data.isComplete != 1;

  if (isCurrent || isOtherActive) {
    const topicsCompleted: any = data.topicsCompleted;
    let topicsCompletedLength = 0;
    let topicsCompletedArray: any[] = [];
    if (topicsCompleted && topicsCompleted != "") {
      topicsCompletedArray = topicsCompleted.split(",");
      topicsCompletedLength = topicsCompletedArray.length;
    }

    for (let i = 1; i <= 5; i++) {
      let stepObj = {
        completed: i <= topicsCompletedLength,
        active: i == topicsCompletedLength + 1,
      };
      stepBar.push(stepObj);
    }
  }

  if (isCompleted && data.updatedAt) {
    convertUTCToLocal(data.updatedAt, "MMMM");
  }

  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  return (
    <div
      onClick={() => {
        if (!isCurrent && !isRecommended && !isOtherActive && onButtonClick) {
          onButtonClick(data, isOtherActive);
        }
      }}
    >
      <div
        className={`expert-card__container ${
          isCurrent || isRecommended || isOtherActive
            ? "expert-card__container-with-btn"
            : ""
        } ${customClass || ""}`}
        style={{
          backgroundColor: `${
            metaData && metaData.bgColor ? metaData.bgColor : "#45b7d9"
          }`,
        }}
      >
        {isCompleted && (
          <div className="expert-card__complete text-white">
            <div>
              <IonImg
                className="expert-card__complete-checked mr-5"
                src={CheckedCircle}
              />
            </div>
            <LanguageAppText
              tag={"div"}
              value={`Completed in ${" "} ${
                data.updatedAt ? convertUTCToLocal(data.updatedAt, "MMMM") : ""
              }`}
              textKey={"program_completed_month"}
              metaData={{
                programCompletedMonth: `${
                  data.updatedAt
                    ? convertUTCToLocal(data.updatedAt, "MMMM")
                    : ""
                }`,
              }}
            />
            {/* <div>
              Completed in{" "}
              {data.updatedAt ? convertUTCToLocal(data.updatedAt, "MMMM") : ""}
            </div> */}
          </div>
        )}
        <LanguageText
          tag={"div"}
          className="expert-card__title text-white font-medium mb-5"
          value={data.nodeTitle}
        ></LanguageText>
        {/* <div className="expert-card__title text-white font-medium mb-5">
          {data.nodeTitle}
        </div> */}
        <div className="expert-card__name text-white">
          {metaData ? metaData.expertName : ""}
        </div>
        {stepBar.length > 0 && <StepBar data={stepBar} />}
        {metaData && metaData.expertImage && metaData.expertImage.uri && (
          <IonThumbnail className="expert-card__img">
            <img src={getFormattedUrl(metaData.expertImage.uri)} />
          </IonThumbnail>
          // <IonImg
          //   src={getFormattedUrl(metaData.expertImage.uri)}
          //   className="expert-card__img"
          // />
        )}
        {!isCompleted && !isCurrent && !isRecommended && !isOtherActive && (
          <IonButton className="expert-card__know-me ion-button__transparent-with-icon text-white ion-no-margin ion-no-padding mt-10 font-medium">
            <LanguageAppText
              tag={"span"}
              value={"Get to know me better"}
              textKey={"know_me_better"}
            />
            <span className="ion-button__know-me">
              <IonImg src={PolygonIconWhite} />
            </span>
          </IonButton>
        )}
        {}
        {isNewProgram && (
          <IonImg src={ExpertNew} className="expert-card__new" />
        )}

        {(isCurrent || isRecommended || isOtherActive) && (
          <ButtonComponent
            name={
              isCurrent
                ? "Choose your next activity"
                : isRecommended
                ? "Get Started"
                : "Continue"
            }
            textKey={
              isCurrent
                ? "choose_next_activity"
                : isRecommended
                ? "button:get_started"
                : "continue"
            }
            outline={isOtherActive && !isRecommended}
            customClass={`expert__card-btn ${
              isRecommended ? "get-start___btn" : ""
            }`}
            onClick={() => {
              if (onButtonClick) {
                onButtonClick(data, isOtherActive);
              }
            }}
          ></ButtonComponent>
        )}
      </div>
    </div>
  );
};
export default ExpertCard;
