import * as Yup from "yup";
import Messages from "../config/messages";

export const UserProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required(Messages.required_field("Name")),
  gender: Yup.string().required(Messages.required_dropdown("Gender")),
  age: Yup.number().integer().required(Messages.required_field("Age")),
  typeOfBusiness: Yup.string().required(Messages.required_dropdown("Type of Business")),
  salesRange: Yup.string().required(Messages.required_dropdown("Monthly Sales Range")),
});