import React, { useState } from "react";

export const OnboardingContext = React.createContext({});

type Props = {
  children: any;
};

export const OnboardingProvider = (props: Props) => {
  const [onboardingData, setOnoboardingData] = useState(null);

  return (
    <OnboardingContext.Provider value={{ onboardingData, setOnoboardingData }}>
      {props.children}
    </OnboardingContext.Provider>
  );
};
