import { IonButton, IonRippleEffect } from "@ionic/react";
import React, { useContext } from "react";
import { AppContext } from "../../../context/app.context";
import {
  getActivityMetaData,
  stringInject,
} from "../../../utils/common.helper";
import "./style.scss";

interface Props {
  name: string;
  outline?: boolean;
  isEnabled?: boolean;
  onClick?(): void;
  customClass?: string;
  textKey?: any;
  metaData?: any;
}

const ButtonComponent: React.FC<Props> = ({
  name,
  outline,
  isEnabled,
  onClick,
  customClass,
  textKey,
  metaData,
}) => {
  const { language, descEnFile, descFile }: any = useContext(AppContext);
  let btnText: any = name;
  if (textKey) {
    btnText =
      descFile[textKey.toLowerCase()] ||
      descEnFile[textKey.toLowerCase()] ||
      name;
    if (metaData) {
      const formattedData = getActivityMetaData(metaData, btnText, language);
      btnText = stringInject(btnText, formattedData);
    }
  }

  return (
    <IonButton
      className={`${
        outline ? "ion-button__btnoutline" : "ion-button__btnfill"
      } ${customClass || ""}`}
      expand="block"
      type="submit"
      disabled={isEnabled || false}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <IonRippleEffect type="unbounded"></IonRippleEffect>
      {btnText || "Save"}
    </IonButton>
  );
};

export default ButtonComponent;
