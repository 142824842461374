import { Capacitor } from "@capacitor/core";
import { Network } from "@ionic-native/network";
import Messages from "../config/messages";

let toastPreset: any = null;
let toastDismiss: any = null;
let networkState: string = Network.type;

const showMessage = (
  message: string,
  present: any,
  dismiss: any,
  variant = "success"
) => {
  present({
    buttons: [{ text: "OK", handler: () => dismiss() }],
    color: variant,
    message,
    duration: message === Messages.NO_INTERNET_MSG ? 0 : 1500,
  });
};

export const networkCheckInit = (present: any, dismiss: any) => {
  toastPreset = present;
  toastDismiss = dismiss;
  if (Capacitor.getPlatform() == "web") {
    window.addEventListener("offline", () => {
      console.log("network offine!", navigator.onLine);
      showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "danger");
    });

    window.addEventListener("online", () => {
      console.log("network online!", navigator.onLine);
      showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
    });
  } else {
    Network.onConnect().subscribe(() => {
      console.log("network connected!", Network.type);
      if (networkState != Network.type) {
        dismiss();
        setTimeout(() => {
          showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
        }, 500);
        networkState = Network.type;
      }
    });

    Network.onDisconnect().subscribe(() => {
      networkState = Network.type;
      dismiss();
      setTimeout(() => {
        showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "danger");
      }, 500);
    });

    Network.onChange().subscribe(() => {
      // console.log("network connected!", Network.type);
      // networkState = Network.type;
      // if (Network.type == "none") {
      //   setTimeout(() => {
      //     showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "danger");
      //   }, 500);
      // } else {
      //   setTimeout(() => {
      //     showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
      //   }, 500);
      // }
    });
    isConnectedToInternet();
  }
};

export const isConnectedToInternet = () => {
  let status = true;
  if (Capacitor.getPlatform() === "web") {
    status = navigator.onLine;
  } else {
    status = Network.type != "none";
  }
  if (!status)
    showMessage(Messages.NO_INTERNET_MSG, toastPreset, toastDismiss, "danger");

  return status;
};
