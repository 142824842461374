import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../../components/common/button-component/back";
import RegisterHeader from "../../components/common/register-header";
import Divider from "../../components/divider";
import "./style.scss";
import FacebookIcon from "../../assets/images/icons/icon-facebook.svg";
import GoogleIcon from "../../assets/images/icons/icon-google.svg";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import { AppContext } from "../../context/app.context";
import {
  handleFacebookSignin,
  handleGoogleSingin,
} from "../../services/firebase.service";
import { addSocialToken } from "../../services/user.service";
import LanguageAppText from "../../components/languageapptext";

const AccountSecurity: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const fromPage: string = state.fromPage || "";

  const redirectToTOC = () => {
    history.replace({
      pathname: "/termsandconditions",
    });
  };

  const handleFBLogin = async () => {
    const tokenResp: any = await handleFacebookSignin();
    if (!tokenResp.error) {
      const resp: any = await addSocialToken(tokenResp);
      if (!resp.error) {
        redirectToTOC();
      } else {
        toastMessage(tokenResp.message, "danger");
      }
    } else {
      toastMessage(tokenResp.message, "danger");
    }
  };

  const handleGoogleLogin = async () => {
    const tokenResp: any = await handleGoogleSingin();
    if (!tokenResp.error) {
      const resp: any = await addSocialToken(tokenResp);
      if (!resp.error) {
        redirectToTOC();
      } else {
        toastMessage(tokenResp.message, "danger");
      }
    } else {
      toastMessage(tokenResp.message, "danger");
    }
  };

  return (
    <IonPage className="page-account-security">
      <RegisterHeader
        pageName="Account security"
        textKey={"account_security"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <LanguageAppText
          tag={"h1"}
          className="page-content__title"
          value={`${fromPage == "not-matched"
          ? "Keep your account safe"
          : "Before we begin"}`}
          textKey={"before_we_begin"}
        />
        <LanguageAppText
          tag={"p"}
          className="page-content__infotext"
          value={"To keep your account more secure, you can connect Ovante with Google or Facebook."}
          textKey={"connect_facebook_google"}
        />
        <div className="page-content__whitebox" onClick={handleFBLogin}>
          <IonImg className="whitebox__socialicon" src={FacebookIcon} />
          <LanguageAppText
            tag={"p"}
            className="whitebox__content"
            value={"Connect Facebook"}
            textKey={"connect_facebook"}
          />
          <IonImg src={PolygonIcon} />
        </div>
        <div className="page-content__whitebox" onClick={handleGoogleLogin}>
          <IonImg className="whitebox__socialicon" src={GoogleIcon} />
          <LanguageAppText
            tag={"p"}
            className="whitebox__content"
            value={"Connect Google"}
            textKey={"connect_google"}
          />
          <IonImg src={PolygonIcon} />
        </div>
        <Divider></Divider>
        <div className="page-content__whitebox" onClick={redirectToTOC}>
          <LanguageAppText
            tag={"p"}
            className="whitebox__content p0"
            value={"I will do this later"}
            textKey={"do_this_later"}
          />
          <IonImg src={PolygonIcon} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AccountSecurity;
