import firebase from "firebase/app";
import 'firebase/auth';
import "@codetrix-studio/capacitor-google-auth";
import { FacebookLoginResponse } from '@rdlabo/capacitor-facebook-login';
import { Capacitor, Plugins } from '@capacitor/core';

const firebaseConfig = {
    apiKey: "AIzaSyA0bTUv2DM_Zx5ZrRCZYPmAviVuWe9dKWM",
    authDomain: "ovante-dev.firebaseapp.com",
    projectId: "ovante-dev",
    storageBucket: "ovante-dev.appspot.com",
    messagingSenderId: "506663036592",
    appId: "1:506663036592:web:e23f3dbb4a82675592fd75",
    measurementId: "G-7417CDFBG7"
  }

export const firebaseInit = () => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
  }
}

const webGoogleSignIn = async() => {
  try{
    const provider = new firebase.auth.GoogleAuthProvider();
    const resp: any = await firebase.auth().signInWithPopup(provider)
    const credentialResp = resp.credential;
    const idToken = credentialResp.idToken;
    var credential = await firebase.auth.GoogleAuthProvider.credential(idToken);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e){
    return {
      error: true,
      message: e.message
    }
  }
}

const webFacebookSignIn = async() => {
  try{
    const provider = new firebase.auth.FacebookAuthProvider();
    const resp: any = await firebase.auth().signInWithPopup(provider)
    const credentialResp = resp.credential;
    // This gives you a Google Access Token. You can use it to access the Google API.
    const token = credentialResp.accessToken;
    const credential = await firebase.auth.FacebookAuthProvider.credential(token);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e){
    return {
      error: true,
      message: e.message
    }
  }
}

const mobileFacebookLogin = async() => {
  try{
    const { FacebookLogin } = Plugins;
    const FACEBOOK_PERMISSIONS = ['email'];
    const result: any = await <FacebookLoginResponse>FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    const token = result.accessToken.token
    const credential = await firebase.auth.FacebookAuthProvider.credential(token);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e){
    return {
      error: true,
      message: e.message
    }
  }
}

const mobileGoogleLogin = async() => {
  try{
    const res: any = await Plugins.GoogleAuth.signIn();
    const idToken = res.authentication.idToken;
    var credential = await firebase.auth.GoogleAuthProvider.credential(idToken);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e){
    return {
      error: true,
      message: e.message
    }
  }
}

const webSocialLogin = async (credential: any) => {
  const respSignIn = await firebase.auth().signInWithCredential(credential);
  const firebaseToken = await firebase.auth().currentUser?.getIdToken()
  return { firebaseToken }
}

export const handleFacebookSignin = async() => {
  let resp: any = null
  if(Capacitor.getPlatform() == "web"){
    resp = await webFacebookSignIn();
  } else {
    resp = await mobileFacebookLogin();
  }
  return resp
}

export const handleGoogleSingin = async() => {
  let resp: any = null
  if(Capacitor.getPlatform() == "web"){
    resp = await webGoogleSignIn();
  } else {
    resp = await mobileGoogleLogin();
  }
  return resp
}

//singout to close previous facebook session
export const handleFacebookSignout = async () => {
  if(Capacitor.getPlatform() == "web"){
    await firebase.auth().signOut();
  } else {
    await Plugins.FacebookLogin.logout();
  }
}

//singout to close previous google session
export const handleGoogleSignout = async () => {
  if(Capacitor.getPlatform() == "web"){
    await firebase.auth().signOut();
  } else {
    await Plugins.GoogleAuth.signOut();
  }  
}