import { Plugins } from "@capacitor/core";
import { File } from "@ionic-native/file";
import { SocialSharing } from "@ionic-native/social-sharing";
import moment from "moment";
import { useEffect, useState } from "react";
import { api, APPLE_STORE_URL, PLAY_STORE_URL } from "../config/appconfig";


const { ZipExtractPlugin } = Plugins;

export const isNullOrEmptyText = (v: string) =>
  v == undefined || v == null || v.length == 0;

export const isObjNullOrEmpty = (obj: any) =>
  obj == undefined || obj == null || Object.keys(obj).length === 0;

export const serializePostMessageData = (data: any) => {
  if (typeof data === "object") {
    return JSON.stringify(data);
  } else if (typeof data === "string") {
    return data;
  } else {
    return `${data}`;
  }
};

export const useStateCallback = (initilValue: any, callBack: any) => {
  const [state, setState] = useState(initilValue);
  useEffect(() => {
    setTimeout(() => {
      callBack(state);
    }, 250);
  }, [state]);
  return [state, setState];
};

export const formatPhoneForForm = (val: string) => {
  let formatedNumber = val.replace("+91", "");
  return formatedNumber;
};

export const formatPhoneToReadable = (val: string) => {
  let formatedNumber = val.replace("+91", "");
  formatedNumber = "+91" + formatedNumber.replace("-", "");
  return formatedNumber;
};

export const formatPhoneNumber = (val: string) => {
  if (val && val.length > 0) {
    let formatedText = val.replace("+91", "");
    formatedText = formatedText.replace("-", "");
    const splitText: any = chunkString(formatedText, 5) || [];
    if (splitText.length > 1) {
      let finalText = "";
      for (let i = 0; i < splitText.length; i++) {
        finalText = finalText + splitText[i].toString() + "-";
      }
      formatedText = finalText.substring(0, finalText.length - 1);
    }

    return formatedText;
  }
  return val;
};

const chunkString = (str: string, length: number) => {
  return str.match(new RegExp(".{1," + length + "}", "g"));
};

export const handleOnValueChange = (name: string, val: any, setVal: any) => {
  setVal(name, val);
};

export const getFileDirectory = () => {
  const directory = File.dataDirectory;
  return directory;
};

export const getAudioProgress = (seconds: number) => {
  if (seconds >= 0) {
    const duration = new Date(seconds * 1000).toISOString().substr(14, 5);
    return duration;
  }
  return "00:00";
};

export const getFormattedUrl = (url: string) => {
  return !url.startsWith("https" || "http")
    ? encodeURI(`${api.apiUrl}/file/download/${url}`)
    : url;
};

export function getFileNameFromURL(urlString: string) {
  var index = urlString.substring(urlString.lastIndexOf("/") + 1);
  return index;
}

export const convertFilepath = (path: string) => {
  return !path.startsWith("file:///")
    ? path.replace("file:/", "file:///")
    : path;
};

export const random = () =>
  `${Math.floor(Math.random() * 100000)}-${Date.now()}`;

export const sliceIntoChunks = (arr: any, chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const formatDateToSingleChar = (date: string, ogDate?: string) => {
  if (date.includes("seconds ago")) {
    return "Just Now";
  } else if (date.includes("a minute ago")) {
    return "1m ago";
  } else if (date.includes("minutes ago")) {
    return date.replace(" minutes ago", "m ago");
  } else if (date.includes("an hour ago")) {
    return "1h ago";
  } else if (date.includes("hours ago")) {
    return date.replace(" hours ago", "h ago");
  } else if (date.includes("a day ago")) {
    return "1d ago";
  } else if (date.includes("days ago")) {
    return date.replace(" days ago", "d ago");
  } else if (date.includes("a month ago") || date.includes("months ago")) {
    const dateFormatted = moment().diff(moment(ogDate), "weeks");
    return dateFormatted + "w ago";
  } else if (date.includes("a year ago")) {
    return "1y ago";
  } else if (date.includes("years ago")) {
    return date.replace(" years ago", "y ago");
  }
};

export const checkExpiresOn = (date: any) => {
  const localDate = moment(convertUTCToLocal(date));
  const diff = localDate.diff(moment(), "days");
  console.log(diff);
  return diff <= 0
    ? "expired"
    : diff == 1
    ? "tomorrow"
    : localDate.format("DD MMM");
};

export const socialSharing = async (
  message: string | undefined,
  subject: string | undefined,
  file?: string | undefined,
  url?: string | undefined
) => {
  await SocialSharing.share(message, subject, file, url);
};

export const convertUTCToLocal = (date: string, format: string = "") => {
  return moment.utc(date).local().format(format);
};

export const convertLocalToUTC = (date: string, format: string = "") => {
  return moment(date).utc().format(format);
};

export const formatDate = (date: string, format: string = "") => {
  return moment(date).format(format);
};

export const unZipDownloadedFile = async (
  srcPath: string,
  destPath: string,
  folderName: string
): Promise<number> => {
  // const isExist = await File.checkDir(destPath, folderName);
  // if(isExist){
  //   await File.removeRecursively(destPath, folderName);
  // }

  await File.createDir(destPath, folderName, true);
  return new Promise<number>(async (resolve, reject) => {
    await ZipExtractPlugin.unzip({
      source: srcPath, destination:`${destPath}${folderName}`
    }).then(({ result }: any) => {
      resolve(result);
    })
  });
};

export const formatStringToJSON = (str: string) => {
  let formattedString = str;
  try {
    formattedString = JSON.parse(str);
  } catch (e) {
    return formattedString;
  }
  return formattedString;
};

export const formatNumber = (number: number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const stringInject = (str: string, data: any) => {
  if (typeof str === "string" && data instanceof Array) {
    return str.replace(/({\d})/g, function (i: any) {
      return data[i.replace(/{/, "").replace(/}/, "")];
    });
  } else if (typeof str === "string" && data instanceof Object) {
    if (Object.keys(data).length === 0) {
      return str;
    }

    for (let key in data) {
      return str.replace(/({([^}]+)})/g, function (i) {
        let key = i.replace(/{/, "").replace(/}/, "");
        if (!data[key]) {
          return i;
        }

        return data[key];
      });
    }
  } else if (
    (typeof str === "string" && data instanceof Array === false) ||
    (typeof str === "string" && data instanceof Object === false)
  ) {
    return str;
  } else {
    return false;
  }
};

export const getActivityMetaData = (
  metaData: any,
  desc: string,
  userLanguage?: string
) => {
  const keys = Object.keys(metaData).filter((keyName) =>
    desc.includes(`{${keyName}}`)
  );
  // const keys = Object.keys(metaData);
  // let filter = keys.filter((keyName) => desc.includes(`{${keyName}}`));
  // console.log("filter", keys, filter);
  let replaceObj: any = {};

  for (var k of keys) {
    const formatSting = formatStringToJSON(metaData[k]);
    if (typeof formatSting === "string") {
      replaceObj[k] = formatSting;
    } else if (userLanguage) {
      replaceObj[k] = formatSting[userLanguage] || formatSting["en"];
    } else {
      replaceObj[k] = formatSting;
    }
  }
  return replaceObj;
};

export const redirectToStore = (os: string) => {
  let url = PLAY_STORE_URL;
  if (os === "ios") {
    url = APPLE_STORE_URL;
  }

  window.open(url, "_blank");
};
