import "./style.scss";

import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRange,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import BottomDrawer from "../../components/bottomdrawer";
import BackDrop from "../../components/common/backdrop";
import UserProfileForm from "../../components/form/userprofile";
import { OnboardingContext } from "../../context/onboarding.contex";
import { getSalesRange } from "../../services/app.service";
import ObserverService from "../../services/observer.service";
import ButtonComponent from "../../components/common/button-component";
import { useFormik } from "formik";
import { UserProfileSchema } from "../../schemas/userprofile.schema";
import { AppContext } from "../../context/app.context";
import { uploadFile, uploadFileWeb } from "../../services/filetransfer.service";
import { useHistory } from "react-router";
import { saveProfile } from "../../services/user.service";
import { APP_DEFAULT_SCREEN, PROFILE_STARTED } from "../../config/appconfig";
import { chooseFromGallery, imageCapture } from "../../utils/media.helper";
import { Capacitor } from "@capacitor/core";
import storageSevice from "../../services/storage.sevice";
import Loader from "../../components/common/loader";
import LanguageAppText from "../../components/languageapptext";
const DiagnosticQuiz: React.FC = () => {
  const inputFile: any = useRef(null);

  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const SALES_TYPE = "sales";
  const GENDER_TYPE = "gender";
  const PICKER_TYPE = "picker";
  const { onboardingData, setOnoboardingData }: any =
    useContext(OnboardingContext);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);
  const [salesRangeData, setSalesRangeData] = useState([]);
  const [profileData, setProfileData]: any = useState(
    onboardingData == null ? null : onboardingData.profileData
  );
  const [profileImg, setProfileImg]: any = useState(
    onboardingData == null ? null : onboardingData.profileImg
  );
  const [gender, setGender] = useState(
    onboardingData == null ? "" : onboardingData.gender
  );
  const [salesRange, setSalesRange] = useState(
    onboardingData == null ? "" : onboardingData.salesRange
  );
  const [initialValues, setInitialValues] = useState(
    onboardingData == null
      ? {
          name: "",
          gender: gender,
          age: "",
          typeOfBusiness: "",
          salesRange: salesRange,
        }
      : onboardingData
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserProfileSchema,
    onSubmit: (values) => {
      storageSevice.set(PROFILE_STARTED, false);
      handleSubmitForm(values);
    },
  });

  useEffect(() => {
    fetchSalesRange();
    storageSevice.set(PROFILE_STARTED, true);
  }, []);

  const fetchSalesRange = async () => {
    const resp: any = await getSalesRange();
    if (!resp.error) {
      setSalesRangeData(resp);
    }
  };

  const handleGenderSelect = (e: any) => {
    const val = e.detail.value;
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      ObserverService.handleEvents(GENDER_TYPE, { state: "close" });
    }
    initialValues["gender"] = val.toLowerCase();
    setGender(e.detail.value);
  };

  const handleSalesRangeSelect = (e: any) => {
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      ObserverService.handleEvents(SALES_TYPE, { state: "close" });
    }
    initialValues["saleRange"] = e.detail.value;
    setSalesRange(e.detail.value);
  };

  const handleSalesRangeClick = () => {
    setIsBackDropOpen(true);
    ObserverService.handleEvents(SALES_TYPE, { state: "open" });
  };

  const handleGenderClick = () => {
    setIsBackDropOpen(true);
    ObserverService.handleEvents(GENDER_TYPE, { state: "open" });
  };

  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    let thumbRes: any = null;
    const postData: any = values;
    if (profileData) {
      if (Capacitor.getPlatform() === "web") {
        thumbRes = await uploadFileWeb(profileData);
      } else {
        thumbRes = await uploadFile(
          profileData.uri || profileData.path,
          profileData.mediaType || profileData.type,
          profileData.name || ""
        );
      }
      if (!thumbRes.error) {
        postData["profilePicFileId"] =
          Capacitor.getPlatform() === "web"
            ? thumbRes.id
            : JSON.parse(thumbRes).id;
      }
    }
    postData["gender"] = postData.gender.toLowerCase();
    postData["isRegistered"] = true;
    const response: any = await saveProfile(postData);
    setLoading(false);
    if (!response.error) {
      setOnoboardingData(null);
      history.push({
        pathname: "/onboarding/diagnosticquestionary",
      });
      // history.replace(APP_DEFAULT_SCREEN);
    } else {
      toastMessage(response.message, "danger");
    }
  };

  const handlePickerSelect = async (picker: string) => {
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      ObserverService.handleEvents(PICKER_TYPE, { state: "close" });
    }
    if (picker === "camera") {
      const imageData = await imageCapture();
      if (imageData) {
        setProfileImg(Capacitor.convertFileSrc(imageData.path));
        setProfileData(imageData);
      }
    } else {
      const imageData = await chooseFromGallery("image/*");
      if (imageData) {
        setProfileImg(imageData.dataURI);
        setProfileData(imageData);
      }
    }
  };

  const handlePickerClick = () => {
    if (Capacitor.getPlatform() === "web" && inputFile && inputFile.current) {
      inputFile.current.click();
    } else {
      setIsBackDropOpen(true);
      ObserverService.handleEvents(PICKER_TYPE, { state: "open" });
    }
  };

  const selectWebFile = (file: File) => {
    setProfileImg(URL.createObjectURL(file));
    setProfileData(file);
  };
  const renderGender = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={gender}
          onIonChange={handleGenderSelect}
        >
          <IonItem>
            <IonLabel>Male</IonLabel>
            <IonRadio slot="end" value="Male" />
          </IonItem>
          <IonItem>
            <IonLabel>Female</IonLabel>
            <IonRadio slot="end" value="Female" />
          </IonItem>
        </IonRadioGroup>
      </IonList>
    );
  };

  const renderSalesItems = () => {
    return salesRangeData.map((item: any, index: any) => {
      return (
        <IonItem key={index}>
          <IonLabel>{item}</IonLabel>
          <IonRadio slot="end" value={item} />
        </IonItem>
      );
    });
  };

  const renderSalesRange = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={salesRange}
          onIonChange={handleSalesRangeSelect}
        >
          {renderSalesItems()}
        </IonRadioGroup>
      </IonList>
    );
  };

  const renderPicker = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup className="ion-radio-group">
          <IonItem
            onClick={() => {
              handlePickerSelect("camera");
            }}
          >
            <IonLabel>Take a picture</IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              handlePickerSelect("gallery");
            }}
          >
            <IonLabel>Choose from gallery</IonLabel>
          </IonItem>
        </IonRadioGroup>
      </IonList>
    );
  };

  return (
    <>
      <IonPage className="page-diagnostic">
        <IonContent fullscreen={true} className="page-content">
          <div className="page-content__header stretch-container">
            <IonRange
              min={0}
              max={10}
              className="page-content__range"
              value={1}
            ></IonRange>
            <LanguageAppText
              tag={"h1"}
              className="header-title"
              value={"Tell us a bit more about yourself. You can do this later too!"}
              textKey={"tell_about_yourself"}
            />
          </div>
          {salesRangeData.length > 0 && (
            <>
              <UserProfileForm
                formik={formik}
                initialValues={initialValues}
                gender={gender}
                salesRange={salesRange}
                profileImg={profileImg}
                profileData={profileData}
                handleGenderClick={handleGenderClick}
                handleSalesRangeClick={handleSalesRangeClick}
                handleEditPickClick={handlePickerClick}
              ></UserProfileForm>
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(e: any) => {
                  console.log(e.target.files);
                  selectWebFile(e.target.files[0]);
                }}
              />
              <div className="stretch-container">
                {loading ? (
                  <Loader />
                ) : (
                  <ButtonComponent
                    name="Proceed"
                    textKey={"proceed"}
                    onClick={formik.handleSubmit}
                  ></ButtonComponent>
                )}
              </div>
            </>
          )}
        </IonContent>
        <BackDrop
          backdropVisible={isBackDropVisible}
          isOutsideClick={true}
        ></BackDrop>
      </IonPage>

      {salesRangeData.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 101,
            width: "100%",
          }}
        >
          <BottomDrawer type={SALES_TYPE} hideBackDrop={handleBackDrop}>
            {renderSalesRange()}
          </BottomDrawer>
          <BottomDrawer type={GENDER_TYPE} hideBackDrop={handleBackDrop}>
            {renderGender()}
          </BottomDrawer>
          <BottomDrawer type={PICKER_TYPE} hideBackDrop={handleBackDrop}>
            {renderPicker()}
          </BottomDrawer>
        </div>
      )}
    </>
  );
};
export default DiagnosticQuiz;
