import { Capacitor } from "@capacitor/core";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN, api, SELECTED_LANG } from "../config/appconfig";
import Messages from "../config/messages";
import { isNullOrEmptyText } from "../utils/common.helper";
import { isConnectedToInternet } from "./network.service";
import storageSevice from "./storage.sevice";

let _singleton = true;
let _instance: HttpService;

class HttpService {
  protected baseURL: string = "";
  public axiosApi!: AxiosInstance;

  constructor() {
    this.init();
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use HttpService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new HttpService();
      _singleton = true;
    }

    return _instance;
  }

  protected getBaseUrl = async () => {
    this.baseURL = api.apiUrl;
  };

  public init = (): any => {
    this.getBaseUrl();
    const baseURL = this.baseURL;
    this.axiosApi = axios.create({
      baseURL: baseURL,
    });
    this.requestInterceptor();
    this.responseInerceptor();
  };
  protected requestInterceptor(): any {
    this.axiosApi.interceptors.request.use(
      async (config: any) => {
        const lang = await storageSevice.get(SELECTED_LANG);
        config.headers["language"] = lang || "en";
        config.headers["platform"] =
          Capacitor.getPlatform() === "web" ? "web" : "mobile";
        config.headers["os"] =
          Capacitor.getPlatform() === "web"
            ? "browser"
            : Capacitor.getPlatform();
        return config;
      },
      (error: any) => {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.config);
        }
        return Promise.reject(error);
      }
    );
  }

  protected responseInerceptor(): any {
    this.axiosApi.interceptors.response.use(
      (response: any) => {
        console.log("response", JSON.stringify(response));
        return response.data;
      },
      (error: any) => {
        if (!isConnectedToInternet()) {
          return {
            error: true,
            message: Messages.NO_INTERNET_MSG,
          };
        }
        if (error.response) {
          let errorData = error.response.data;
          if (errorData.statusCode == 401) {
            window.location.href = "/register";
          }
          errorData =
            typeof errorData === "object" ? errorData : { message: errorData };
          console.log("error.response: ", JSON.stringify(errorData));
          return {
            ...errorData,
            error: true,
          };
        } else {
          console.log("error.config: ", JSON.stringify(error.config));
          return {
            error: true,
            message: Messages.SERVER_ERROR,
          };
        }
      }
    );
  }

  public execute = async (reqObj: any) => {
    if (!isConnectedToInternet()) {
      return {
        error: true,
        message: Messages.NO_INTERNET_MSG,
      };
    }
    let axiosConfig: AxiosRequestConfig = {
      method: reqObj.method || HttpMethod.GET,
      url: reqObj.url,
      headers: reqObj.headers || {},
    };
    const token = await storageSevice.get(ACCESS_TOKEN);
    if (!isNullOrEmptyText(token)) {
      axiosConfig.headers["Authorization"] = "Bearer " + token;
    }

    Object.assign(axiosConfig, reqObj.requestOptions);
    if (
      reqObj.method === HttpMethod.POST ||
      reqObj.method === HttpMethod.PATCH ||
      reqObj.method === HttpMethod.PUT
    ) {
      axiosConfig.data = reqObj.data;
    }
    const resp = await this.axiosApi(axiosConfig);
    return resp;
  };
}

export default HttpService.instance;

export const HttpMethod = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  PATCH: "patch",
};
