import { IonButton, IonImg, useIonRouter } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import Back from "../../../assets/images/icons/back.svg";

interface Props {
  icon?: string;
  onBack?: () => void;
}

const BackButton: React.FC<Props> = ({ icon, onBack }) => {
  const history = useHistory();

  const handleOnClick = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  return (
    <IonButton className="ion-button__back-btn" onClick={handleOnClick}>
      <IonImg src={icon ? icon : Back} className="back-icon" />
    </IonButton>
  );
};

export default BackButton;
