import { programActivityStatus } from "./program.service";
import SQLiteService from "./sqlite.service";

const TABLE_NAME = "programSessions";

const COLUMN_ID = "id";
const COLUMN_PROGRAM_ID = "programId";
const COLUMN_TOPIC_ID = "topicId";
const COLUMN_ACTIVITY_ID = "activityId";
const COLUMN_START_TIME = "startTime";
const COLUMN_COMPLETED_TIME = "completedTime";
const COLUMN_TOTAL_TIME = "totalTime";
const COLUMN_VISIT_COUNT = "visitCount";
const COLUMN_IS_SYNC = "isSync";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_PROGRAM_ID +
  " int, " +
  COLUMN_TOPIC_ID +
  " int, " +
  COLUMN_ACTIVITY_ID +
  " int, " +
  COLUMN_START_TIME +
  " TEXT," +
  COLUMN_COMPLETED_TIME +
  " TEXT default null," +
  COLUMN_TOTAL_TIME +
  " int," +
  COLUMN_VISIT_COUNT +
  " int," +
  COLUMN_IS_SYNC +
  " boolean default false)";

let _singleton = true;
let _instance: ProgramSessionService;
let sqliteService: SQLiteService = SQLiteService.instance;
class ProgramSessionService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use ProgramSessionService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new ProgramSessionService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(data: any) {
    // const resp = await this.getData(COLUMN_NAME, name);
    let result;

    let values = "";
    values += "" + data.programId;
    values += "," + data.topicId;
    values += "," + data.activityId;
    values += ",'" + data.startTime;
    values += "'," + data.totalTime;
    values += "," + data.visitCount + "";
    let col =
      COLUMN_PROGRAM_ID +
      ", " +
      COLUMN_TOPIC_ID +
      ", " +
      COLUMN_ACTIVITY_ID +
      ", " +
      COLUMN_START_TIME +
      ", " +
      COLUMN_TOTAL_TIME +
      ", " +
      COLUMN_VISIT_COUNT;
    result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);

    console.log("insert", result);
    return result;
  }

  public async update(column: string, value: any, id: any) {
    let result;
    result = await sqliteService.update(
      `update ${TABLE_NAME} set ${column} = '${value}' where ${COLUMN_ACTIVITY_ID} = ${id}`
    );
    console.log("update", column, value, result);
    return result;
  }

  public async getData(column?: string, value?: any) {
    let query = "select * from " + TABLE_NAME;
    if (column) {
      query = `select * from ${TABLE_NAME} where ${column} = '${value}'`;
    }
    let result: any = await sqliteService.getData(query);
    return result;
  }

  public async delete(activityId: any) {
    let query =
      "delete from " +
      TABLE_NAME +
      " where " +
      COLUMN_ACTIVITY_ID +
      " = " +
      activityId;
    let result = await sqliteService.delete(query);
    return result;
  }

  public async getSyncData() {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_COMPLETED_TIME} != '' and ${COLUMN_IS_SYNC} = '0'`;
    let result: any = await sqliteService.getData(query);
    if (!result.error && result.length > 0) {
      let progress: any = [];
      for (let i = 0; i < result.length; i++) {
        progress.push({
          programId: result.item(i).programId,
          topicId: result.item(i).topicId,
          activityId: result.item(i).activityId,
          startTime: result.item(i).startTime,
          completedTime: result.item(i).completedTime,
          totalTime: result.item(i).totalTime,
          visitCount: result.item(i).visitCount,
        });
      }
      const statusRes: any = await programActivityStatus({ progress });
      if (!statusRes.error) {
        let query = `delete from ${TABLE_NAME} where ${COLUMN_COMPLETED_TIME} != '' and ${COLUMN_IS_SYNC} = '0'`;

        let result = await sqliteService.delete(query);
      }
    }
  }
}

export default ProgramSessionService;
