import {
  IonImg,
  IonButton,
  IonContent,
  useIonRouter,
  IonPage,
} from "@ionic/react";
import { Formik, Form, FormikHelpers, FormikValues } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import BackButton from "../../components/common/button-component/back";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import Popup from "../../components/common/popup";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import {
  ACCESS_TOKEN,
  APP_DEFAULT_SCREEN,
  INIT_STATE_APP,
  PROFILE_STARTED,
  USER_SETTINGS,
} from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import { CheckPhoneSchema, OtpSchema } from "../../schemas/auth.schema";
import {
  loginVerifyOTP,
  resendOTP,
  verifyOTP,
} from "../../services/auth.service";
import storageSevice from "../../services/storage.sevice";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  handleOnValueChange,
  isObjNullOrEmpty,
} from "../../utils/common.helper";
import "./style.scss";
import Success from "../../assets/images/icons/success-icon.svg";
import {
  changeSettings,
  userAppInstall,
  userProfile,
} from "../../services/user.service";
import { registertToFCM } from "../../services/notification.service";
import Messages from "../../config/messages";
import Loader from "../../components/common/loader";
import LanguageAppText from "../../components/languageapptext";

const ConfirmOTP: React.FC = () => {
  const {
    language,
    toastMessage,
    setSettings,
    setHasToken,
    setAppState,
    setUserDetails,
  }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const optTime = 60 * 1;
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime]: any = useState("01:00");
  const fromPage: string = state.from;
  const phoneNumber = state.phoneNumber;
  const phoneNumbers: any = state.phoneNumbers;
  const initialValues = {
    otp: state.otp,
  };

  useEffect(() => {
    startTimer(optTime);
  }, []);

  const startTimer = (duration: any) => {
    let timer: any = duration;
    let minutes: any, seconds: any;
    const interval = setInterval(function () {
      const min: any = timer / 60;
      const sec: any = timer % 60;
      minutes = parseInt(min, 10);
      seconds = parseInt(sec, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      if (--timer < 0) {
        timer = duration;
      }
      setRemainingTime(minutes + ":" + seconds);
      if (interval != null && minutes == "00" && seconds == "00") {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleOtpSubmit = async (values: any, actions: any) => {
    setLoading(true);
    let resp: any;
    if (fromPage == "login" || fromPage == "changenumber") {
      resp = await loginVerifyOTP({
        phoneNumber: phoneNumber,
        otp: String(values.otp),
      });
    } else {
      resp = await verifyOTP({
        phoneNumber: phoneNumber,
        otp: String(values.otp),
      });
    }
    if (!resp.error) {
      setHasToken("true");
      await storageSevice.set(ACCESS_TOKEN, resp.token);
      registertToFCM();
      if (fromPage != "changenumber") {
        userAppInstall();
      }
      if (fromPage == "login" || fromPage == "changenumber") {
        let route = fromPage == "login" ? APP_DEFAULT_SCREEN : "/settings";
        const respProfile: any = await userProfile();
        if (!respProfile.error) {
          const settingData = {
            notifications: respProfile.settings.notifications,
            languageCode: language,
          };
          const resp: any = await changeSettings(settingData);
          setLoading(false);
          await storageSevice.set(USER_SETTINGS, JSON.stringify(settingData));
          setSettings(settingData);
          setAppState(INIT_STATE_APP);
          setUserDetails(respProfile);
          if (!respProfile.isPartnerUser && !respProfile.howYouHearAboutUs) {
            history.replace({
              pathname: "/hearaboutus",
              state: {
                fromPage: fromPage,
                phoneNumbers: phoneNumbers,
              },
            });
          } else if (!respProfile.tcConfirm) {
            route = "/termsandconditions";
          } else if (!respProfile.isRegistered) {
            // const profiileStarted = await storageSevice.get(PROFILE_STARTED);
            // if (profiileStarted) {
            //   route = "/onboarding/diagnostic";
            // } else {
            //   route = "/register";
            // }
            route = "/onboarding/diagnostic";
          } else if (!respProfile.diagnosticQuiz) {
            route = "/onboarding/diagnosticquestionary";
          }
          if (fromPage == "changenumber") {
            toastMessage(Messages.CHANGE_NUMBER_SUCCESS, "success");
          }
          history.replace({
            pathname: route,
          });
        } else if (respProfile.statusCode && respProfile.statusCode === 400) {
          setLoading(false);
          history.replace({
            pathname: "/register",
          });
        }
      } else {
        setLoading(false);
        if (resp.isPartnerUser) {
          setShowSuccess(true);
        } else {
          history.replace({
            pathname: "/hearaboutus",
            state: {
              fromPage: fromPage,
              phoneNumbers: phoneNumbers,
            },
          });
        }
      }
    } else {
      setLoading(false);
      toastMessage(resp.message, "danger");
      actions.setFieldError("otp", resp.message);
    }
  };

  const handleSuccessClick = async () => {
    history.replace({
      pathname: "/accountSecurity",
      state: {
        fromPage: fromPage,
      },
    });
  };

  const handleChangeNumberClick = async () => {
    if (fromPage == "not-matched") {
      history.replace({
        pathname: "/changenumbers",
        state: {
          phoneNumbers: phoneNumbers,
        },
      });
    } else {
      const path = fromPage == "login" ? "/loginwithphone" : "/register";
      history.replace({
        pathname: path,
        state: {
          phoneNumber: phoneNumber,
        },
      });
    }
  };

  const handleResendClick = async () => {
    const resp: any = await resendOTP({
      phoneNumber: phoneNumber,
    });
    if (!resp.erro) {
      console.log(resp);
      startTimer(optTime);
    } else {
      toastMessage(resp.message, "danger");
    }
  };

  return (
    <IonPage className="page-confirm-otp">
      <RegisterHeader
        pageName="Confirm OTP"
        textKey={"confirm_otp"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        <div>
          <LanguageAppText
            tag={"h1"}
            className="page-content__title"
            value={"We’ve sent an OTP via SMS!"}
            textKey={"otp_sent"}
          />
          <LanguageAppText
            tag={"p"}
            className="page-content__infotext p0"
            value={`Please check your messages for a 4 digit code sent to ${" "} ${formatPhoneNumber(
              phoneNumber
            )}`}
            textKey={"check_otp_code"}
            metaData={{ phoneNumber: formatPhoneNumber(phoneNumber) }}
          />
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            validationSchema={OtpSchema}
            onSubmit={handleOtpSubmit}
          >
            {({ values, setFieldValue, isValid, dirty, errors }) => (
              <Form>
                <FormGroup>
                  <InputText
                    name="otp"
                    value={values.otp}
                    type="number"
                    label="Enter OTP"
                    labelKey={"enter_otp"}
                    maxlength={4}
                    placeholder="- - - -"
                    onChange={(val: string) => {
                      handleOnValueChange("otp", val, setFieldValue);
                    }}
                  ></InputText>
                  <ValidationMessage name="otp"></ValidationMessage>
                </FormGroup>
                <FormGroup>
                  <div onClick={handleResendClick}>
                    {remainingTime != "00:00" ? (
                      <LanguageAppText
                        tag={"p"}
                        className="page-content__otptext-resent"
                        value={`${remainingTime} OTP has been resent!`}
                        textKey={"otp_resent"}
                      />
                    ) : (
                      // <p className="page-content__otptext-resent">
                      //   Resend OTP after {remainingTime}
                      // </p>
                      <LanguageAppText
                        tag={"p"}
                        className="page-content__otptext pt-0"
                        value={"Resend OTP"}
                        textKey={"resend_otp"}
                      />
                    )}
                  </div>
                  <div onClick={handleChangeNumberClick}>
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__otptext pt-0"
                      value={"Change phone number"}
                      textKey={"change_phone"}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="page-content__button">
                    {loading ? (
                      <Loader />
                    ) : (
                      <ButtonComponent
                        name="Submit"
                        textKey={"submit"}
                        isEnabled={!isObjNullOrEmpty(errors)}
                      />
                    )}
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
        {showSuccess && (
          <Popup>
            <IonImg src={Success} className="ion-popup__success-icon" />
            <LanguageAppText
              tag={"h1"}
              className="ion-popup__success-text m0 p0"
              value={"Success!"}
              textKey={"success"}
            />
            <LanguageAppText
              tag={"p"}
              className="ion-popup__text m0 p0"
              value={"You have been registered on Ovante."}
              textKey={"registered_success"}
            />
            <ButtonComponent
              name="Get started"
              textKey={"button:get_started"}
              onClick={handleSuccessClick}
            ></ButtonComponent>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ConfirmOTP;
