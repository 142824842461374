import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { File } from "@ionic-native/file";
import { IonButton, IonImg } from "@ionic/react";
import Duration from "./duration";
import "./style.scss";
import { useHistory } from "react-router";
import { formatNumber } from "../../utils/common.helper";
import LikeWhite from "../../assets/images/icons/ic_like_white.svg";
import Like from "../../assets/images/icons/ic_like.svg";

interface Props {
  isPlaying?: boolean;
  url?: string;
  onVideoEnd?: () => void;
  isFullScreen?: boolean;
  onFullScreenChange?: (isFullScreen: boolean) => void;
  hideControl?: boolean;
  showBottomBack?: boolean;
  thumbnail?: string;
  hideFullScreen?: boolean;
  hideProgress?: boolean;
  likeCount?: number;
  isLiked?: boolean;
  onLikePress?: () => Promise<boolean>;
  hideLike?: boolean;
  onVideoPlay?: () => void;
}
const VideoPlayer = (props: Props) => {
  const {
    isPlaying,
    url,
    onVideoEnd,
    onFullScreenChange,
    hideControl,
    showBottomBack,
    thumbnail,
    hideFullScreen,
    hideProgress,
    likeCount,
    isLiked,
    onLikePress,
    hideLike,
    onVideoPlay,
  } = props;
  let player: any;
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(props.isFullScreen);
  const history: any = useHistory();
  const [liked, setLiked] = useState(isLiked);

  useEffect(() => {
    setIsFullScreen(props.isFullScreen);
  }, [props.isFullScreen]);
  const onFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (onFullScreenChange) {
      onFullScreenChange(!isFullScreen);
    }
  };

  const ref = (playerRef: any) => {
    player = playerRef;
  };

  const togglePlayPause = () => {
    setPlaying(!playing);
    onVideoPlay && onVideoPlay();
  };

  const handleProgress = (event: any) => {
    if (!seeking) {
      setPlayed(event.played);
      setLoaded(event.loaded);
    }
  };

  const handleDuration = (duration: any) => {
    console.log("onDuration", duration);
    setDuration(duration);
  };

  const handleEnded = () => {
    setPlaying(false);
    onVideoEnd && onVideoEnd();
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    player.seekTo(parseFloat(e.target.value));
    setPlaying(true);
  };

  const handleLikedPress = async () => {
    // const res = onLikePress && (await onLikePress());
    // setLiked(!liked);
    if (onLikePress) {
      setLiked(!liked);
      const res = await onLikePress();
      if (!res) {
        setLiked(!liked);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: isFullScreen ? window.innerHeight - 15 : 232,
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          height: isFullScreen ? "100%" : 232,
        }}
        className="fullscreen"
      >
        <div
          style={{
            // justifyContent: "space-between",
            // display: "flex",
            // flexDirection: "column",
            height: isFullScreen ? "100%" : 232,
          }}
          // className="fullscreen"
          onClick={togglePlayPause}
        >
          <ReactPlayer
            ref={ref}
            playing={playing}
            className="react-player"
            // url={(window as any).Ionic.WebView.convertFileSrc(
            //   `${File.externalDataDirectory}test.mp4`
            // )}
            url={url}
            // light={ "https://picsum.photos/seed/picsum/200/300"}
            light={thumbnail || false}
            width="100%"
            height={isFullScreen ? "100%" : 232}
            controls={false}
            onError={(error: any, data: any) => {
              console.log("error", error);
              console.log("data", data);
            }}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleEnded}
            playIcon={<div className="icon__play">play</div>}
          />
          {!thumbnail && !playing && (
            <div className="icon__play hide-thumnail-play-icon">play</div>
          )}
        </div>
        <div className="ion-video__controllers">
          {showBottomBack && (
            <div
              className="ion-video__controllers_align-left"
              onClick={() => {
                history.goBack();
              }}
            >
              <div style={{ color: "white" }}>Back</div>
            </div>
          )}
          {!hideControl && (
            <>
              <div className="ion-video__controllers_align-left">
                {isFullScreen ? (
                  <div className="ion-video__time">
                    <Duration seconds={duration * played} />/
                    <Duration seconds={duration} />
                  </div>
                ) : !hideFullScreen ? (
                  <div className="ion-video__icon-max ">
                    <div
                      onClick={onFullScreen}
                      className="ion-video__icon icon__max"
                    ></div>
                  </div>
                ) : null}
              </div>
              {isFullScreen && playing && (
                <div
                  className="ion-video__icon icon__pause"
                  onClick={togglePlayPause}
                ></div>
              )}
              {/* {isFullScreen && !playing && (
            <div className="ion-video__icon icon__play-fullscreen" onClick={togglePlayPause}>
            </div>
          )} */}
              <div className="ion-video__controllers_align-right">
                {isFullScreen ? (
                  !hideFullScreen ? (
                    <div className="ion-video__icon-min" onClick={onFullScreen}>
                      <div className="ion-video__icon icon__min"></div>
                    </div>
                  ) : null
                ) : !hideLike ? (
                  <div
                    className="ion-video__icon-like"
                    onClick={handleLikedPress}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className={`ion-video__icon ${
                        !liked ? "ion-video__icon-black" : ""
                      }`}
                    >
                      {/* className={`ion-video__icon ${!liked ? "ion-video__icon-black" : ""}`}> */}
                      {!liked ? (
                        <IonImg src={Like} />
                      ) : (
                        <IonImg src={LikeWhite} />
                      )}
                    </div>
                    <div className="icon__like__text">
                      {/* className={`icon__like__text ${!liked ? "icon__like__text-black" : ""}`}> */}{" "}
                      {formatNumber(Math.max(likeCount || 0, 0))}
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
      {!hideProgress && (
        <div>
          <div className="ion-video__progress-bar">
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              style={{ width: "100%" }}
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              onTouchStart={handleSeekMouseDown}
              onTouchEnd={handleSeekMouseUp}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default VideoPlayer;
