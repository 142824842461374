import { Media, MediaObject } from "@ionic-native/media";
import { File } from "@ionic-native/file";
import { IonButton, IonImg } from "@ionic/react";
import { useState } from "react";
import { Capacitor } from "@capacitor/core";
import React from "react";
import { getAudioProgress } from "../../utils/common.helper";
import PlayAudio from "../../assets/images/icons/play-orange.svg";
import PauseAudio from "../../assets/images/icons/pause_orange.svg";
import { Plugins } from "@capacitor/core";
import "./style.scss";
import ObserverService from "../../services/observer.service";

interface Props {
  filePath: string;
}
interface State {
  audioDuration: string;
  currentDuration: string;
  isPlaying: boolean;
}
const { App } = Plugins;
export default class AudioPlayer extends React.Component<Props, State> {
  audioPlayer: any;
  audioTimer: any;
  audio: any;
  observer: any = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      audioDuration: "00:00",
      currentDuration: "00:00",
      isPlaying: false,
    };
    this.audioPlayer = new Audio(props.filePath);
  }

  componentDidMount() {
    this.audioPlayer.onloadedmetadata = () => {
      console.log("audio dur", this.audioPlayer.duration);
      const duration = getAudioProgress(this.audioPlayer.duration);
      this.setState({
        audioDuration: duration,
      });
    };

    App.addListener("appStateChange", ({ isActive }) => {
      if (!isActive) {
        if (this.state.isPlaying) {
          this.destroyAudio();
          // this.audioPlayer = null;
        }
      }
    });

    this.observer = ObserverService.observable.subscribe((value: any) => {
      console.log("event", JSON.stringify(value));
      if (
        this.state.isPlaying &&
        value &&
        value.type == "audio" &&
        value.data.audioFile != this.props.filePath
      ) {
        if (this.state.isPlaying) {
          this.destroyAudio();
          // this.audioPlayer = null;
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.filePath != this.props.filePath) {
      this.audioPlayer = null;
      this.audioPlayer = new Audio(this.props.filePath);
      this.audioPlayer.onloadedmetadata = () => {
        console.log("audio dur", this.audioPlayer.duration);
        const duration = getAudioProgress(this.audioPlayer.duration);
        this.setState({
          audioDuration: duration,
        });
      };
    }
  }

  componentWillUnmount() {
    if (this.state.isPlaying) {
      this.destroyAudio();
      // this.audioPlayer = null;
    }

    if (this.observer) this.observer.unsubscribe();
  }
  playRecord = () => {
    ObserverService.handleEvents("audio", { audioFile: this.props.filePath });
    this.setState({ isPlaying: true });
    this.audioPlayer
      .play()
      .then(() => {
        // console.log("duration", audioPlayer.duration);

        this.audioTimer = setInterval(() => {
          //   console.log(audioPlayer.currentTime);
          const currentDuration = getAudioProgress(
            this.audioPlayer.currentTime
          );
          this.setState({
            currentDuration: currentDuration,
          });
          this.audioPlayer.onended = () => {
            this.destroyAudio();
          };
        }, 1000);
      })
      .catch((e: any) => {
        console.log("audio", e);
      });
  };

  destroyAudio = () => {
    this.audioPlayer.pause();
    this.audioPlayer.currentTime = 0;
    this.setState({ isPlaying: false });

    if (this.audioTimer) clearInterval(this.audioTimer);
    // this.audioPlayer = null;
    this.audioTimer = null;
    // this.setState({
    //   audioDuration: "00:00",
    // });
  };

  pauseAudio = () => {
    this.audioPlayer.pause();
    this.setState({ isPlaying: false });
  };
  render() {
    const { audioDuration, currentDuration, isPlaying } = this.state;
    return (
      <div className={`recorder-post__audio-player ${!isPlaying ? "pause-width" : ""}`}>
        {!isPlaying ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
            onClick={this.playRecord}
          >
            <IonImg src={PlayAudio} className="post__audio-play" />
            <div className="record-btn-text text-black ml-5">
              {audioDuration}
            </div>
          </div>
        ) : (
          <div
            onClick={this.pauseAudio}
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IonImg src={PauseAudio} className="post__audio-pause" />
            <div className="record-btn-text text-black ml-5">
              {`${currentDuration}/${audioDuration}`}
            </div>
          </div>
        )}
      </div>
    );
  }
}
