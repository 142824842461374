import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import { APP_DEFAULT_SCREEN, INIT_STATE_APP } from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import MedalImg from "../../assets/images/badges/badge-ready-to-grow.svg";
import UserDefault from "../../assets/images/tool-user-default.svg";
import ProgressBadge from "../../assets/images/badges/badge-ready-to-grow.svg";
import ProgressLock from "../../assets/images/badges/home-badge-lock.svg";
import "./style.scss";
import LanguageAppText from "../../components/languageapptext";
import { getFirstEarnedUsers } from "../../services/program.service";
import Messages from "../../config/messages";
import Loader from "../../components/common/loader";
import { formatNumber, getFormattedUrl } from "../../utils/common.helper";

const Congratulation = () => {
  const { setAppState, toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData]: any = useState([]);
  const [userCount, setUserCount]: any = useState(0);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const resp: any = await getFirstEarnedUsers();
    setLoading(false);
    if (!resp.error) {
      setUserData(resp.data);
      setUserCount(resp.userCount);
    } else {
      toastMessage(Messages.SERVER_ERROR, "danger");
    }
  };
  const onContinueClick = () => {
    setAppState(INIT_STATE_APP);
    if (process.env.REACT_APP_WEB == "true") {
      history.replace({
        pathname: "/installovante",
      });
    } else {
      history.replace({
        pathname: APP_DEFAULT_SCREEN,
      });
    }
  };
  return (
    <IonPage className="page-congratulations large-header abstract-img-light-blue">
      <IonContent className="page-content pb-0">
        {loading ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <div className="page-congratulations__container text-white">
            <LanguageAppText
              tag={"div"}
              className="congratulations-title font-medium mb-10"
              value={"Congratulations!"}
              textKey={"congratulations"}
            />
            <LanguageAppText
              tag={"div"}
              className="congratulations-medal mb-20"
              value={"You have earned a badge!"}
              textKey={"earned_a_badge"}
            />
            {/* <div className="congratulations-medal mb-20">
             You have earned a medal!
           </div> */}
            <div className="medal-img__container mb-20">
              <IonImg src={MedalImg} className="medal-img" />
            </div>
            <div className="congratulations-step font-medium mb-5">
              The first step
            </div>
            <div className="congratulations-intro-video font-medium mb-10">
              Watching the first intro video with first expert
            </div>
            <div className="congratulations-top-three__container mb-40">
              <div className="top-three__container-inner">
                <div className="congratulations-top-three-img">
                  {userData &&
                    userData.map((item: any, index: any) => {
                      return (
                        <IonImg
                          key={index}
                          src={getFormattedUrl(item.uri)}
                          className="congratulations-user__img"
                        />
                      );
                    })}
                </div>
                <LanguageAppText
                  tag={"div"}
                  className="congratulations-user__text font-medium"
                  value={`+${
                    userCount ? formatNumber(userCount) : 0
                  } have earned this badge`}
                  textKey={"user_badge_count"}
                  metaData={{
                    userBadgeCount: userCount
                      ? userCount > 3
                        ? `+${formatNumber(userCount)}`
                        : ""
                      : "",
                  }}
                />
                {/* <div className="congratulations-user__text font-medium">
                 +5,209 have earned this medal
               </div> */}
              </div>
            </div>
            <div className="congratulations-level__container mt-5 mb-10">
              <div className="congratulations-progress-level">
                <div className="congratulations-progress-title font-medium">
                  Level 1
                </div>
                <div className="congratulations-progress-level__badges">
                  <IonImg
                    src={ProgressBadge}
                    className="progress-level__badge-img"
                  />
                  <IonImg
                    src={ProgressLock}
                    className="progress-level__lock-img"
                  />
                  <IonImg
                    src={ProgressLock}
                    className="progress-level__lock-img"
                  />
                  <IonImg
                    src={ProgressLock}
                    className="progress-level__lock-img"
                  />
                  <IonImg
                    src={ProgressLock}
                    className="progress-level__lock-img"
                  />
                </div>
              </div>
            </div>
            <LanguageAppText
              tag={"div"}
              className="congratulations-level__earn-badge font-medium"
              value={"Earn 4 more badges to get to Level 2!"}
              textKey={"badge_remaining_count_level"}
              metaData={{ badgeRemainingCount: 4, nextLevel: 2 }}
            />
          </div>
        )}
      </IonContent>
      {!loading && (
        <div className="page-congratulations__btn-container stretch-container bg-light-orange mt-0">
          <ButtonComponent
            name="Continue"
            textKey={"continue"}
            customClass="page-congratulations__btn"
            onClick={onContinueClick}
          />
        </div>
      )}
    </IonPage>
  );
};
export default Congratulation;
