// Must import the package once to make sure the web support initializes
import "@capacitor-community/http";
import { Capacitor, FilesystemDirectory, Plugins } from "@capacitor/core";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer";
import { ACCESS_TOKEN, api } from "../config/appconfig";
import { getFileDirectory, getFileNameFromURL } from "../utils/common.helper";
import httpService, { HttpMethod } from "./http.service";
import storageSevice from "./storage.sevice";

const BASE_URL = api.apiUrl;

const { Http } = Plugins;

export const downloadFile = async (
  url: string,
  filePath: string,
  ext: string = "zip"
) => {
  try {
    if (Capacitor.getPlatform() == "ios") {
      const fileTransfer: FileTransferObject = FileTransfer.create();
      const path: string = getFileDirectory() + `${filePath}.${ext}`;
      const res = await fileTransfer.download(BASE_URL + url, path);
      return {
        data: res.nativeURL,
        message: "Download success",
      };
    } else {
      const res = await Http.downloadFile({
        url: `${BASE_URL}${url}`,
        filePath: `${filePath}.${ext}`,
        fileDirectory: FilesystemDirectory.Data,
      });
      return {
        data: res.path,
        message: "Download success",
      };
    }
  } catch (error) {
    console.log("download error", error);
  }
  return {
    error: true,
    message: "Download Failed",
  };
};

export const uploadFile = async (
  filePath: string,
  mimeType: string,
  fileName?: string
) => {
  try {
    const token = await storageSevice.get(ACCESS_TOKEN);
    const name: string =
      fileName && fileName != "" ? fileName : getFileNameFromURL(filePath);
    const options: any = {
      fileName: name,
      mimeType: mimeType,
      headers: {
        Authorization: "Bearer " + token,
      },
      chunkedMode: false,
    };
    const fileTransfer: FileTransferObject = FileTransfer.create();
    const res = await fileTransfer.upload(
      filePath,
      encodeURI(BASE_URL + "/file/upload"),
      options
    );
    return res.response || { error: true, message: "File upload failed" };
  } catch (error) {
    return {
      error: true,
      message: "File upload failed",
    };
  }
};

export const uploadFileWeb = async (file: File): Promise<any> => {
  if (!file) {
    return null;
  }
  const formData = new FormData();
  formData.set("file", file);
  const response = await httpService.execute({
    url: `/file/upload`,
    method: HttpMethod.POST,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
