import { ErrorMessage } from "formik";
import React from "react";
import "./style.scss";

interface Props {
  name: string;
  message?: string;
  customValidation?: boolean;
}

const ValidationMessage: React.FC<Props> = ({
  name,
  message,
  customValidation,
}) => {
  return (
    <div className="validationError">
      <div className="error">
        {customValidation ? message : <ErrorMessage name={name} />}
      </div>
    </div>
  );
};

export default ValidationMessage;
